<template>
  <app-dialog :dialogShow="dialogShow" :title="title" :closeAction="handleOnClose">
    <template v-slot:body>
      <div class="confirmationForm-tab">
        <v-card color="light_gray" elevation="0">
          <v-form ref="refundCancelOrderForm">
            <v-row>
              <v-col cols="12">
                <div class="text-body-2 unbox_secondary--text">{{ confirmationMessage }}</div>
              </v-col>

              <template v-if="requireRemark">
                <v-col cols="12">
                  <app-form-field type="select" v-model="returnType" :placeholder="$t(`label.cancellationReason`)" :items="returnTypes" :rules="$validation.requiredRules()" />
                </v-col>

                <v-col cols="12" v-if="isRemark">
                  <app-form-field v-model.trim="remark" type="textarea" :placeholder="$t(`label.enterCancellationReason`)" :rules="$validation.requiredRules()"></app-form-field>
                </v-col>
              </template>

              <v-col cols="12">
                <div class="text-center">
                  <v-btn block class="white--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8" @click="handleOnSubmit" height="56px" color="unbox_black" elevation="0">
                    {{ $t(`label.confirm`) }}
                  </v-btn>
                </div>
                <div class="text-center">
                  <v-btn
                    text
                    class="black--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8"
                    @click="handleOnCancel"
                    height="56px"
                    block
                    color="unbox_black"
                    elevation="0"
                  >
                    {{ $t(`label.cancel`) }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </div>
    </template>
  </app-dialog>
</template>

<script>
export default {
  name: 'cancelOrderDialog',
  props: {
    dialogShow: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    onClose: {
      type: Function,
      required: true
    },
    onCancel: {
      type: Function,
      required: true
    },
    confirmationAction: {
      type: Function
    },
    requireRemark: {
      type: Boolean,
      required: true
    },
    confirmationMessage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      remark: '',
      returnType: ''
    }
  },
  methods: {
    handleOnSubmit() {
      if (this.$refs.refundCancelOrderForm.validate()) {
        this.confirmationAction({ remark: this.remark, returnType: this.returnType })
      }
    },
    handleOnCancel() {
      this.resetForm()
      this.onCancel()
    },
    handleOnClose() {
      this.resetForm()
      this.onClose()
    },
    resetForm() {
      this.remark = ''
      this.returnType = ''
    }
  },
  computed: {
    returnTypes() {
      return this.$store.state.lookup.orderReturnTypes
    },
    isRemark() {
      return this.returnTypes.find((t) => t.value === this.returnType)?.text.toLowerCase() === 'others' || false
    }
  }
}
</script>

<style scoped lang="scss">
.confirmationForm-tab {
  width: 100%;
  max-width: 300px;
}
</style>
