<template>
  <div class="orderHistory-box rounded-xl py-4 my-4">
    <v-row justify="space-between" no-gutters class="px-4">
      <v-col cols="12" sm="6">
        <table class="orderHistory-info-table d-block">
          <tr class="d-sm-table-row d-block">
            <td class="history-table-left-info d-sm-table-cell d-block">
              <p class="mb-0 text-sm-body-1 text-body-2 text_light_gray--text">{{ $t(`label.orderId`) }}</p>
            </td>
            <td class="d-sm-table-cell d-block">
              <p class="mb-0 text-sm-body-1 text-body-2">{{ detail.orderReferenceNumber }}</p>
            </td>
          </tr>
          <tr class="d-sm-table-row d-block">
            <td class="history-table-left-info d-sm-table-cell d-block">
              <p class="mb-0 text-sm-body-1 text-body-2 text_light_gray--text">{{ $t(`label.orderDate`) }}</p>
            </td>
            <td class="d-sm-table-cell d-block">
              <p class="mb-0 text-sm-body-1 text-body-2">{{ $shared.getDateFormat(detail.createdDateTime) }}</p>
            </td>
          </tr>
        </table>
      </v-col>
      <v-col cols="12" sm="6">
        <table class="orderHistory-info-table text-sm-right text-center">
          <tr>
            <td>
              <p class="mb-0 py-2 my-sm-0 text-sm-body-1 text-body-2 font-weight-bold unbox_primary--text">
                {{ $t(`historyRefundOrderStatus.${detail.orderStatus}`) }}
              </p>
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
        </table>
      </v-col>
    </v-row>
    <v-divider class="my-4 orderHistory-info-divider"></v-divider>
    <v-row
      v-for="(orderItem, index) in detail.orderItems"
      justify="space-between"
      no-gutters
      class="px-4"
      :key="`${detail.referenceNumber}_${index}_${orderItem.product_display_sku}`"
      :class="isPayment ? '' : index >= 1 ? 'd-none' : ''"
    >
      <v-col cols="12" sm="auto" class="text-sm-left text-center">
        <table class="orderHistory-info-table">
          <tr>
            <td class="history-table-left-info product-image d-block d-sm-table-cell mx-auto mr-sm-0">
              <img class="full-width history-product-image-box rounded-lg" :src="`${shared.MEDIA_SERVER_URL}/products/variants/${orderItem.product_display_sku}.png`" alt="" />
            </td>
            <td class="d-block d-sm-table-cell">
              <p class="text-body-1 font-weight-bold my-1">{{ orderItem.product_name }}</p>
              <p class="text-sm-body-1 text-body-2 my-1">
                {{ getProductVariantDisplay(orderItem.product_variants) }}
              </p>
              <p class="unbox_red--text font-weight-bold text-sm-body-1 text-body-2 my-1">{{ `${$shared.formatCurrency(orderItem.purchase_price)}` }}</p>
            </td>
          </tr>
        </table>
      </v-col>
      <v-col cols="12" sm="auto" class="text-sm-left text-center">
        <span class="text-sm-body-1 text-body-2">{{ `${$t(`label.quantity`)}: ` }}</span>
        <span class="text-h6 font-weight-bold mb-2">{{ `x${orderItem.quantity}` }}</span>
      </v-col>
    </v-row>
    <v-divider class="my-4 orderHistory-info-divider"></v-divider>
    <v-row justify="space-between" no-gutters class="px-4">
      <v-col cols="12" sm="6">
        <table class="orderHistory-info-table">
          <tr>
            <td class="history-table-left-info">
              <p class="mb-0 text-sm-body-1 text-body-2 text_light_gray--text">{{ $t(`label.totalItems`) }}</p>
            </td>
            <td class="text-right text-sm-left">
              <p class="mb-0 text-sm-body-1 text-body-2 text_light_gray--text">{{ `${detail.orderItems.length} ${$t(`label.items`)}` }}</p>
            </td>
          </tr>
          <tr>
            <td class="history-table-left-info">
              <p class="mb-0 text-sm-body-1 text-body-2 text_light_gray--text">{{ $t(`label.orderTotal`) }}</p>
            </td>
            <td class="text-right text-sm-left">
              <p class="mb-0 unbox_red--text font-weight-bold text-sm-body-1 text-body-2">{{ `${$shared.formatCurrency(detail.totalPrice)}` }}</p>
            </td>
          </tr>
        </table>
      </v-col>
      <v-col cols="12" sm="6">
        <table class="orderHistory-info-table text-right">
          <tr>
            <td>
              <div v-if="!isPayment">
                <v-btn outlined color="#64748B" dense class="text-capitalize rounded-lg d-none d-sm-inline-block" @click="viewDetail(detail)">{{ $t(`label.viewDetails`) }}</v-btn>
                <v-btn height="50" outlined color="#64748B" block class="font-weight-bold text-capitalize rounded-lg d-sm-none text-md-h6 text-body-2 py-md-8 py-6" @click="viewDetail(detail)">
                  {{ $t(`label.viewDetails`) }}
                </v-btn>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div v-if="!isPayment && detail.receiptPath != null">
                <v-btn class="d-none d-sm-inline-block backgroundColor--white" elevation="0" tile icon @click="getReceipt()">
                  <v-icon v-text="'$downloadIcon'"></v-icon>
                </v-btn>
                <v-btn
                  block
                  height="50"
                  color="black"
                  class="font-weight-bold text-capitalize rounded-lg d-sm-none text-md-h6 text-body-2 py-md-8 py-6 white--text"
                  elevation="0"
                  tile
                  @click="getReceipt()"
                >
                  {{ $t(`label.downloadReceipt`) }}
                </v-btn>
              </div>
            </td>
          </tr>
        </table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { SHARED } from '@/constants'
export default {
  props: {
    detail: {
      type: Object,
      required: true,
      default: null
    },
    viewDetail: {
      type: Function,
      required: false,
      default: () => ({})
    },
    openConfirmationDialog: {
      type: Function,
      required: false,
      default: () => ({})
    },
    isPayment: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    shared: SHARED
  }),
  methods: {
    getReceipt() {
      let link = `${SHARED.MEDIA_SERVER_URL}/${this.detail.receiptPath}`
      window.open(link, '_blank')
    },
    getProductVariantDisplay(variant) {
      return variant
        .map((o) => {
          return o.value
        })
        .join('/')
    }
  }
}
</script>

<style lang="scss">
.orderHistory-box {
  border: 2px solid #d5dde0;
}

.history-table-left-info {
  width: 180px;
}

.history-product-image-box {
  border: 1px solid #d5dde0;
  width: 80%;
}

.orderHistory-info-table {
  width: 100%;
  td {
    padding: 5px 0;
  }
}

.orderHistory-info-divider {
  border-top: 2px solid #d5dde0;
}

@media only screen and (max-width: 599px) {
  .history-table-left-info {
    width: 110px;
    &.product-image {
      width: 200px;
    }
  }
  .history-product-image-box {
    width: 100%;
  }
}
</style>
