<template>
  <v-card color="transparent" tile elevation="0">
    <v-card class="py-sm-8 py-4" color="light_gray" tile elevation="0">
      <v-row justify="center" no-gutters>
        <v-col cols="11" lg="9">
          <span class="text-sm-h5 text-body-1 font-weight-bold">{{ $t(`label.myOrders`) }}</span>
        </v-col>
      </v-row>
    </v-card>

    <v-card color="light_gray" tile elevation="0">
      <v-row justify="center" no-gutters>
        <v-col cols="11" lg="9">
          <v-tabs center-active v-model="selectedHistoryTab" background-color="transparent" color="unbox_primary">
            <v-tab v-for="tab in historyTabs" :key="tab.value">
              <span class="text-capitalize unbox_black--text text-sm-body-1 text-body-2 font-weight-bold">{{ tab.text }}</span>
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
    </v-card>
    <v-row justify="center" class="my-8" no-gutters v-if="selectedHistoryTab == historyTabs.length - 2 && isViewHistoryDetail == false">
      <v-col cols="11" lg="9">
        <v-row justify="center" class="history-status-selection text-center" no-gutters>
          <v-col cols="5" class="py-3" :class="selectedHistoryStatus == 'processing' ? 'active' : ''" @click="selectedHistoryStatus = 'processing'">
            <div class="d-inline-block font-weight-bold text-sm-body-1 text-body-2">
              {{ $t(`label.processing`) }}
            </div>
          </v-col>
          <v-col cols="5" class="py-3" :class="selectedHistoryStatus == 'completed' ? 'active' : ''" @click="selectedHistoryStatus = 'completed'">
            <div class="d-inline-block font-weight-bold text-sm-body-1 text-body-2">{{ $t(`label.completed`) }}</div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row justify="center" class="my-8" no-gutters>
      <v-col cols="11" lg="9">
        <v-card class="pa-6 px-sm-8 px-4 rounded-xl" color="transparent" tile elevation="15">
          <!-- History Detail -->
          <div class="history-detail-page pr-0 pr-sm-4 mb-6" v-if="isViewHistoryDetail && historyDetail">
            <div class="mb-2">
              <v-btn class="unbox_primary--text text-capitalize" elevation="0" :ripple="false" text depressed @click="isViewHistoryDetail = false">
                <v-icon small left v-text="'$chevronLeftIcon'" class="stroke--unbox_primary mr-2" />
                {{ $t(`label.back`) }}
              </v-btn>
            </div>
            <div v-if="historyTabs[selectedHistoryTab].value !== 'history'">
              <app-order-detail :detail="historyDetail" :pickUpAddress="historyDetailOutletAddress" :isCompletedTab="historyTabs[selectedHistoryTab].value === 'completed'"></app-order-detail>
            </div>
            <div v-else>
              <app-refund-detail :detail="historyDetail"></app-refund-detail>
            </div>
            <div class="mt-10">
              <v-row justify="center">
                <v-col cols="12" sm="5" md="4">
                  <v-btn outlined block color="black" class="rounded-lg font-weight-bold text-lg-h6 text-body-2 py-6" @click="contactUnbox">
                    <v-icon class="stroke--black mr-2" v-text="'$whatappIcon'" />
                    {{ $t(`label.contactUnbox`) }}
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  sm="5"
                  md="4"
                  v-if="
                    historyDetail.transactionType.toLowerCase() == 'preorder' &&
                    historyDetail.orderStatus.toLowerCase() == 'success' &&
                    historyDetail.linkReferenceNumber == null &&
                    historyTabs[selectedHistoryTab].value !== 'completed'
                  "
                >
                  <v-btn block color="unbox_red" height="50" class="rounded-lg font-weight-bold white--text text-lg-h6 text-body-2 py-6" @click="() => clearBalanceNow(historyDetail)">
                    {{ $t(`label.clearBalance`) }}
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="5" md="4" v-if="historyDetail.orderStatus.toLowerCase() == 'pendingsubmission'">
                  <v-btn block color="unbox_red" height="50" class="rounded-lg font-weight-bold white--text text-lg-h6 text-body-2 py-6" @click="goToPayNow">
                    {{ $t(`label.payNow`) }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
          <!-- History Listing -->
          <div v-else>
            <div class="history-container unbox_scrollbar pr-sm-4 pr-0 mb-6">
              <div v-if="orderHistory != null && orderHistory.length > 0">
                <div
                  v-for="history in historyTabs[selectedHistoryTab].value == 'toPay'
                    ? orderHistory.filter((x) => x.platform.toLowerCase() != 'pos')
                    : historyTabs[selectedHistoryTab].value == 'history'
                    ? orderHistory.filter((x) => x.platform.toLowerCase() != 'pos' && (x.orderStatus.toLowerCase() != 'cancelled' || x.orderStatus.toLowerCase() != 'failed'))
                    : orderHistory"
                  :key="history.uuid"
                  class="mb-16"
                >
                  <div v-if="historyTabs[selectedHistoryTab].value !== 'history' || (historyTabs[selectedHistoryTab].value === 'history' && selectedHistoryStatus.toLowerCase() == 'processing')">
                    <app-order-summary
                      :detail="history"
                      :openConfirmationDialog="openConfirmationDialog"
                      :viewDetail="viewDetail"
                      :isProcessingTab="historyTabs[selectedHistoryTab].value === 'history' && selectedHistoryStatus.toLowerCase() == 'processing'"
                      :isCompletedTab="historyTabs[selectedHistoryTab].value === 'completed'"
                    ></app-order-summary>
                  </div>
                  <div v-else>
                    <app-refund-summary :detail="history" :openConfirmationDialog="openConfirmationDialog" :viewDetail="viewDetail"></app-refund-summary>
                  </div>
                </div>
                <v-pagination color="unbox_primary" v-model="orderHistoryPagination.pageNumber" :length="orderHistoryPagination.lastPage" :total-visible="7" @input="updatePage"></v-pagination>
              </div>
              <div v-else class="mt-16 pt-16 mb-16 text-center">
                <div class="text-h5 d-block my-2 font-weight-bold">{{ $t(`label.noOrderYet`) }}</div>
                <div class="text-h6 my-2">{{ $t(`label.orderUpdatesWillDisplayedHere`) }}</div>
                <div>
                  <v-btn color="black" max-width="300px" class="mt-4 rounded-lg font-weight-bold text-body-1 py-6 white--text" @click="goToShoppingPage">
                    {{ $t(`label.continueShopping`) }}
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <cancel-order-dialog
      :dialog-show="confirmationDialog"
      :title="confirmationTitle"
      :on-close="() => (confirmationDialog = false)"
      :on-cancel="() => (confirmationDialog = false)"
      :confirmation-message="confirmationMessage"
      :confirmation-action="confirmationAction"
      :require-remark="requireRemark"
    />
  </v-card>
</template>
<script>
import { ORDER_CANCEL_ORDER, ORDER_GET_CANCELLATIONS_HISTORY, ORDER_GET_HISTORY, ORDER_GET_ORDER_INFO, ORDER_REFUND_ORDER, ORDER_UPDATE_STATUS, ORDER_VOID_ORDER } from '@/store/order.module'
import { LOOKUPS_ORDER_STATUSES, LOOKUPS_OUTLET_OUTLETS, LOOKUPS_ORDER_RETURN_TYPE } from '@/store/lookup.module'
import { localeHelper } from '@/utils'
import { SHARED, SESSION } from '@/constants'
import AppOrderDetail from '@/components/history/orderDetail.vue'
import AppRefundDetail from '@/components/history/refundDetail.vue'
import AppOrderSummary from '@/components/history/orderSummary.vue'
import AppRefundSummary from '@/components/history/refundSummary.vue'
import { ROUTE_NAME } from '@/constants/route.constants'
import { CUSTOMER_GET_INFORMATION } from '@/store/customer.module'
import CancelOrderDialog from '@/views/history/cancelOrderDialog'
import moment from 'moment'

export default {
  components: {
    CancelOrderDialog,
    AppOrderDetail,
    AppOrderSummary,
    AppRefundDetail,
    AppRefundSummary
  },
  async created() {
    await this.getOrderStatuses()
    this.getOrderReturnTypes()
    this.getOrderHistory()
    this.getOutlets()
  },
  data() {
    return {
      requireRemark: false,
      refundReason: '',
      selectedHistoryStatus: 'processing',
      isViewHistoryDetail: false,
      shared: SHARED,
      confirmationDialog: false,
      confirmationTitle: '',
      confirmationMessage: '',
      confirmationAction: null,
      action: '',
      selectedHistoryTab: 0,
      historyDetail: null,
      historyDetailOutletAddress: null,
      historyTabs: [
        {
          value: 'toPay',
          text: localeHelper.getMessage(`label.toPay`),
          status: ['pendingsubmission']
        },
        {
          value: 'toShip',
          text: localeHelper.getMessage(`label.toShip`),
          status: ['pendingfulfilment']
        },
        {
          value: 'toReceive',
          text: localeHelper.getMessage(`label.toReceive`),
          status: ['tobereceive']
        },
        {
          value: 'completed',
          text: localeHelper.getMessage(`label.completed`),
          status: ['success']
        },
        {
          value: 'history',
          text: localeHelper.getMessage(`label.cancelAndRefund`)
        },
        {
          value: 'preorders',
          text: localeHelper.getMessage(`label.preorders`),
          status: ['success']
        }
      ],
      filter: {
        status: [],
        isLinkageRefExists: null,
        isCancellationExists: null,
        transactionTypesUuid: null,
        page: 1,
        size: 5
      }
    }
  },
  computed: {
    orderStatuses() {
      return this.$store.state.lookup.orderStatuses
    },
    orderHistory() {
      return this.$store.state.order.orderHistory.data
    },
    orderHistoryPagination() {
      return this.$store.state.order.orderHistory.pagination
    },
    updateOrderStatusResponse() {
      return this.$store.state.order.updateOrderStatusResponse
    },
    outlets() {
      return this.$store.state.lookup.outlets
    },
    refundOrderResponseComplete() {
      return this.$store.state.order.refundOrderResponse.complete
    },
    voidOrderResponseComplete() {
      return this.$store.state.order.voidOrderResponse.complete
    },
    order() {
      return this.$store.state.order.order
    }
  },
  watch: {
    refundOrderResponseComplete() {
      let response = this.$store.state.order.refundOrderResponse

      if (response.complete) {
        if (!response.success) {
          this.$root.$master.responseCompleteDialog(response)
        } else {
          this.$root.$master.responseCompleteDialog(response, localeHelper.getMessage(`message.orderRefundComplete`))
          this.getOrderHistory()
        }
      }
    },
    voidOrderResponseComplete() {
      let response = this.$store.state.order.voidOrderResponse

      if (response.complete) {
        if (!response.success) {
          this.$root.$master.responseCompleteDialog(response)
        } else {
          this.$root.$master.responseCompleteDialog(response, localeHelper.getMessage(`message.orderRefundComplete`))
          this.getOrderHistory()
        }
      }
    },
    selectedHistoryTab() {
      this.filter.page = 1
      this.isViewHistoryDetail = false
      this.historyDetail = null
      let selectedTabValue = null
      selectedTabValue = this.historyTabs[this.selectedHistoryTab]
      if (selectedTabValue.value != 'history') {
        this.getOrderHistory()
      } else {
        if (this.selectedHistoryStatus == 'processing') {
          this.getProcessingCancellationsOrRefunds()
        } else {
          this.getCompletedCancellationsOrRefunds()
        }
      }
    },
    updateOrderStatusResponse() {
      let response = this.$store.state.order.updateOrderStatusResponse

      if (response.complete) {
        if (!response.success) {
          this.$root.$master.responseCompleteDialog(response)
        } else {
          if (this.action == 'cancel') this.$root.$master.responseCompleteDialog(response, localeHelper.getMessage(`message.cancelOrderComplete`))
          else this.$root.$master.responseCompleteDialog(response, localeHelper.getMessage(`message.orderStatusUpdateComplete`))
          this.getOrderHistory()
        }
      }
    },
    selectedHistoryStatus() {
      this.filter.page = 1
      if (this.selectedHistoryStatus == 'processing') {
        this.getProcessingCancellationsOrRefunds()
      } else {
        this.getCompletedCancellationsOrRefunds()
      }
    },
    order() {
      if (this.action == 'paynow') {
        if (this.order != null && this.order.orderStatus != 'pendingsubmission') {
          let response = {
            code: 0,
            success: true,
            action: 'payment'
          }
          this.$root.$master.responseCompleteDialog(response, localeHelper.getMessage(`message.orderItemReleased`))
        } else {
          this.$router.push({
            name: ROUTE_NAME.SHOP_CHECKOUT,
            params: {
              orderId: this.historyDetail.uuid
            }
          })
        }
      }
    }
  },
  mounted() {
    this.getCustomerInformation()
  },
  methods: {
    getCustomerInformation() {
      let data = {
        uuid: localStorage.getItem(SESSION.CUSTOMER_UUID)
      }
      this.$store.dispatch(CUSTOMER_GET_INFORMATION, { data })
    },
    getProcessingCancellationsOrRefunds() {
      let data = { ...this.filter }
      const orderStatuses = ['submitted', 'pendingfulfilment']
      const orderStatusesUuids = this.orderStatuses.filter((y) => orderStatuses.includes(y.name.toLowerCase())).map((s) => s.value)

      data.orderStatusesUuids = orderStatusesUuids
      this.$store.dispatch(ORDER_GET_CANCELLATIONS_HISTORY, { data })
    },
    getCompletedCancellationsOrRefunds() {
      let data = this.filter
      const orderStatuses = ['cancelled', 'refunded', 'voided', 'failed']
      const orderStatusesUuids = this.orderStatuses.filter((y) => orderStatuses.includes(y.name.toLowerCase())).map((s) => s.value)

      data.isCancellationExists = true
      data.status = orderStatusesUuids

      this.$store.dispatch(ORDER_GET_HISTORY, { data })
    },
    getOutlets() {
      this.$store.dispatch(LOOKUPS_OUTLET_OUTLETS)
    },
    async getOrderStatuses() {
      await this.$store.dispatch(LOOKUPS_ORDER_STATUSES)
    },
    getOrderReturnTypes() {
      this.$store.dispatch(LOOKUPS_ORDER_RETURN_TYPE)
    },
    openConfirmationDialog(order, action) {
      this.action = action
      if (action == 'cancelSuccessOrder') {
        this.confirmationTitle = localeHelper.getMessage('label.cancelOrder')
        this.confirmationDialog = true
        this.confirmationMessage = localeHelper.getMessage('label.selectCancellationReason')
        this.confirmationAction = ({ remark, returnType }) =>
          this.cancelSuccessOrder({
            order,
            remark,
            returnType
          })
        this.requireRemark = true
      } else if (action == 'cancel') {
        this.confirmationTitle = localeHelper.getMessage('label.cancelAndRefundRequest')
        this.confirmationDialog = true
        this.confirmationMessage = localeHelper.getMessage('label.cancelOrderConfirmation')
        this.confirmationAction = () => this.cancelOrder(order.uuid)
        this.requireRemark = false
      } else {
        this.confirmationTitle = localeHelper.getMessage('label.cancelAndRefundRequest')
        this.confirmationDialog = true
        this.confirmationMessage = localeHelper.getMessage('label.selectCancellationReason')
        this.confirmationAction = ({ remark, returnType }) => this.voidAndRefundOrder({ order, remark, returnType })
        this.requireRemark = true
      }
    },
    voidAndRefundOrder({ order, remark, returnType }) {
      let todayDate = new Date(new Date().toISOString().slice(0, 10) + 'T00:00:00.000000Z'.toLocaleString('en-US', { timeZone: 'Asia/Singapore' }))
      let settlementCutOffDate = new Date(new Date().toISOString().slice(0, 10) + 'T14:30:00.000000Z'.toLocaleString('en-US', { timeZone: 'Asia/Singapore' }))

      let orderPaymentMadeDateInfo = order.orderStatusHistory.find(
        (x) => x.from_status != null && x.from_status.toLowerCase() == 'pendingsubmission' && x.to_status != null && x.to_status.toLowerCase() == 'pendingfulfilment'
      )
      let orderCreatedDate = moment(orderPaymentMadeDateInfo.created_date_time)
      if (moment(orderCreatedDate).isBetween(moment(todayDate), moment(settlementCutOffDate), undefined, '[]') && order.orderStatus.toLowerCase() !== 'success') {
        this.voidOrder({ order, remark, returnType })
      } else {
        this.refundOrder({ order, remark, returnType })
      }
    },
    voidOrder({ order, remark, returnType }) {
      const orderId = order.uuid

      let data = {
        orderUuid: orderId,
        remark: remark,
        returnType: returnType
      }
      this.confirmationDialog = false
      this.$store.dispatch(ORDER_VOID_ORDER, { data })
    },
    refundOrder({ order, remark, returnType }) {
      const orderId = order.uuid

      let product = []
      order.orderItems.forEach((x) => {
        let haveSerialNumber = x.product_serial_number != null || x.supplier_serial_number != null
        product.push({
          display_sku: x.product_display_sku,
          sku: x.product_sku,
          quantity: x.quantity,
          product_serials: haveSerialNumber
            ? [
                {
                  serial_number: x.product_serial_number === null ? '' : x.product_serial_number,
                  supplier_serial_number: x.supplier_serial_number === null ? '' : x.supplier_serial_number.value,
                  supplier_field: x.supplier_serial_number === null ? '' : x.supplier_serial_number.name
                }
              ]
            : [],
          refund_amount: x.purchase_price,
          refund_percentage: 0
        })
      })
      let data = {
        orderUuid: orderId,
        product: product,
        refundAmount: order.totalPrice,
        remark: remark,
        returnType: returnType
      }
      this.confirmationDialog = false
      this.$store.dispatch(ORDER_REFUND_ORDER, { data })
    },
    goToShoppingPage() {
      this.$router.push({
        name: ROUTE_NAME.SHOP
      })
    },
    cancelSuccessOrder({ order, remark, returnType }) {
      const orderId = order.uuid

      let product = []
      order.orderItems.forEach((x) => {
        let haveSerialNumber = x.product_serial_number != null || x.supplier_serial_number != null
        product.push({
          display_sku: x.product_display_sku,
          sku: x.product_sku,
          quantity: x.quantity
        })
      })
      let data = {
        orderUuid: orderId,
        product: product,
        returnType: returnType,
        remark: remark
      }
      this.confirmationDialog = false
      this.$store.dispatch(ORDER_CANCEL_ORDER, { data })
    },
    cancelOrder(orderId) {
      let data = {
        isSendEmail: false,
        isOrderSealed: false,
        orderUuid: orderId,
        action: 'cancelOrder'
      }
      this.action = 'cancel'
      this.confirmationDialog = false
      this.$store.dispatch(ORDER_UPDATE_STATUS, { data })
    },
    getOrderHistory() {
      let data = this.filter
      let selectedTab = this.historyTabs[this.selectedHistoryTab]

      let p = []

      if (selectedTab.value != 'history') {
        selectedTab.status.forEach((x) => {
          p.push(this.orderStatuses.find((y) => y.name == x).value)
        })
        data.isLinkageRefExists = null
        data.isCancellationExists = null
        data.transactionTypesUuid = null
      }
      if (selectedTab.value == 'preorders') {
        data.isLinkageRefExists = false
        data.isCancellationExists = false
        data.transactionTypesUuid = SHARED.PREORDER_TRANSACTION_TYPE_UUID
      }

      data.status = p
      this.$store.dispatch(ORDER_GET_HISTORY, { data })
    },
    updatePage(page) {
      this.filter.page = page
      let selectedTabValue = null
      selectedTabValue = this.historyTabs[this.selectedHistoryTab]
      if (selectedTabValue.value != 'history') {
        this.getOrderHistory()
      } else {
        if (this.selectedHistoryStatus == 'processing') {
          this.getProcessingCancellationsOrRefunds()
        } else {
          this.getCompletedCancellationsOrRefunds()
        }
      }
    },
    viewDetail(history) {
      this.historyDetail = history
      this.isViewHistoryDetail = true
      this.historyDetailOutletAddress = this.outlets.find((x) => x.uuid == this.historyDetail.outletUuid)
    },
    contactUnbox() {
      window.open('https://api.whatsapp.com/send/?phone=601155550000&text&app_absent=0', '_blank')
    },
    goToPayNow() {
      this.action = 'paynow'
      let data = {
        orderUuid: this.historyDetail.uuid
      }
      this.$store.dispatch(ORDER_GET_ORDER_INFO, { data })
    },
    clearBalanceNow(history) {
      let purchaseProduct = []

      history.orderItems.forEach((x) => {
        purchaseProduct.push({
          productName: x.product_name,
          variation: x.product_variants
            .map((o) => {
              return o.value
            })
            .join('/'),
          productDisplaySku: x.product_display_sku,
          quantity: x.quantity,
          discountedPrice: x.purchase_price,
          product_type: 'Product',
          inCoverableByInsurance: false,
          requiredInsurance: false
        })
      })

      localStorage.setItem(SESSION.CHECKOUT_CART, JSON.stringify(purchaseProduct))
      this.$router.push({
        name: ROUTE_NAME.SHOP_CHECKOUT,
        query: {
          ref_num: history.orderReferenceNumber
        },
        params: {
          action: 'clearbalance'
        }
      })
    }
  }
}
</script>
<style lang="scss">
.confirmationForm-tab {
  width: 100%;
  max-width: 300px;
}

.history-details-ordered-item-table {
  border: 1px solid #d5dde0;
}

.history-container {
  max-height: 1200px;
  overflow-y: auto;
}

.history-status-selection {
  > div {
    border: 2px solid #94a3b8;
    max-width: 180px;
    padding: 10px 0px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    color: #94a3b8;
    &.active {
      background-color: #c7a87e;
      border-color: #c7a87e;
      color: white;
    }
  }
  div:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: 0px;
  }

  div:last-child {
    border-left: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

@media only screen and (max-width: 959px) {
  .history-container {
    max-height: none;
    overflow-y: auto;
  }
}
.v-slide-group__prev--disabled,
.v-slide-group__next--disabled {
  display: none !important;
}
</style>
