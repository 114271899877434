<template>
  <div>
    <!-- History Detail (Delivery Method) -->
    <div class="px-4">
      <div class="font-weight-bold text-body-2">
        <v-icon v-text="'$deliveryTruckIcon'" class="stroke--unbox_primary text-body-1"></v-icon>
        {{ $t(`label.deliveryMethod`) }}
      </div>

      <div class="mt-1 text-sm-body-1 text-body-2">
        {{ detail.deliveryMethodName != null ? $t(`deliveryMethods.${detail.deliveryMethodName.toLowerCase()}`) : '-' }}
      </div>
    </div>
    <v-divider class="my-4 orderHistory-info-divider"></v-divider>
    <!-- History Detail (Tracking ID) -->
    <div class="px-4">
      <div class="font-weight-bold text-body-2">
        <v-icon v-text="'$deliveryTruckIcon'" class="stroke--unbox_primary text-body-1"></v-icon>
        {{ $t(`label.trackingNumber`) }}
      </div>

      <a v-if="detail.trackingNumber != null" :href="`https://t.17track.net/en#nums=${detail.trackingNumber}`" target="_blank" class="mt-1 text-sm-body-1 text-body-2">
        {{ `${detail.courierName} - ${detail.trackingNumber}` }}
      </a>
      <div v-else class="mt-1 text-sm-body-1 text-body-2">-</div>
      <v-btn v-if="detail.trackingNumber != null" class="backgroundColor--white" elevation="0" tile icon @click="copyTrackingNumber()">
        <v-icon class="stroke--light_gray" v-text="'$copyIcon'"></v-icon>
      </v-btn>
    </div>
    <v-divider class="my-4 orderHistory-info-divider"></v-divider>
    <!-- History Detail (Delivery/PickUp Address) -->
    <div class="px-4" v-if="detail.deliveryMethodName != null">
      <div class="font-weight-bold text-body-2">
        <v-icon v-text="'$locationIcon'" class="stroke--unbox_primary text-body-1"></v-icon>
        {{ detail.deliveryMethodName.toLowerCase() == deliveryMethod.SELF_PICKUP ? $t(`label.pickUpAddress`) : $t(`label.deliveryAddress`) }}
      </div>

      <div class="mt-1 text-sm-body-1 text-body-2" v-if="detail.deliveryMethodName.toLowerCase() == deliveryMethod.SELF_PICKUP">
        <div>{{ pickUpAddress.externalName }}</div>
        <div>{{ pickUpAddress.addressOne }}</div>
        <div>{{ pickUpAddress.addressTwo }} {{ pickUpAddress.addressThree }}</div>
        <div>{{ pickUpAddress.postcode }} {{ pickUpAddress.city }} {{ pickUpAddress.state }}</div>
      </div>
      <div class="mt-1 text-sm-body-1 text-body-2" v-else>
        <div>{{ detail.emailPostageCustomerName }}</div>
        <div>{{ detail.emailPostagePhoneNumber }}</div>
        <div>{{ detail.emailPostageAddress }}</div>
      </div>
      <v-divider class="my-4 orderHistory-info-divider"></v-divider>
    </div>

    <!-- History Detail (Billing Address) -->
    <div class="px-4">
      <div class="font-weight-bold text-body-2">
        <v-icon v-text="'$billingIcon'" class="stroke--unbox_primary text-body-1"></v-icon>
        {{ $t(`label.billingAddress`) }}
      </div>

      <div class="mt-1 text-sm-body-1 text-body-2">
        <div>{{ detail.emailCustomerName }}</div>
        <div>{{ detail.emailPhoneNumber }}</div>
        <div>{{ detail.emailBillingAddress }}</div>
      </div>
    </div>
    <!-- History Detail (Order Items) -->
    <div class="orderHistory-box rounded-xl my-4">
      <v-row no-gutters>
        <v-col cols="12" class="pa-4">
          <div class="font-weight-bold text-sm-body-1 text-body-2">
            {{ $t(`label.yourItems`) }}
          </div>
        </v-col>
        <v-col cols="12">
          <v-divider class="orderHistory-info-divider"></v-divider>
        </v-col>
        <v-col cols="12" class="pa-4">
          <v-row
            v-for="(orderItem, index) in isCompletedTab ? detail.orderItems.filter((item) => item.order_statuses_name.toLowerCase() != 'exchanged') : detail.orderItems"
            justify="space-between"
            no-gutters
            :key="`${detail.referenceNumber}_${index}_${orderItem.product_display_sku}`"
          >
            <v-col cols="12" sm="auto" class="text-sm-left text-center">
              <table class="orderHistory-info-table">
                <tr>
                  <td class="history-table-left-info product-image d-block d-sm-table-cell mx-auto mr-sm-0">
                    <img class="full-width history-product-image-box rounded-lg" :src="`${shared.MEDIA_SERVER_URL}/products/variants/${orderItem.product_display_sku}.png`" alt="" />
                  </td>
                  <td class="d-block d-sm-table-cell">
                    <p class="text-body-1 font-weight-bold my-1">{{ orderItem.product_name }}</p>
                    <p class="text-sm-body-1 text-body-2 my-1">
                      {{ getProductVariantDisplay(orderItem.product_variants) }}
                    </p>
                    <p class="unbox_red--text font-weight-bold text-sm-body-1 text-body-2 my-1">{{ `${$shared.formatCurrency(orderItem.selling_price)}` }}</p>
                    <template v-if="Array.isArray(orderItem.service_extra_info)">
                      <template v-for="info in orderItem.service_extra_info.flatMap(Object.entries)">
                        <label :key="info[0] + info[1]" class="text-body-2 font-weight-medium d-block">{{ info[0] }} : {{ info[1] }}</label>
                      </template>
                    </template>
                    <template v-else-if="orderItem.service_extra_info">
                      <template v-for="extraInfoCategory of Object.keys(orderItem.service_extra_info)">
                        <label v-if="orderItem.service_extra_info[extraInfoCategory]" :key="`service${index}-${extraInfoCategory}`" class="text-body-2 font-weight-medium d-block">
                          {{ toStartCase(extraInfoCategory) }} : {{ orderItem.service_extra_info[extraInfoCategory] }}
                        </label>
                      </template>
                    </template>
                    <p v-if="orderItem.service_pin" class="text-body-2 font-weight-medium">
                      {{ $t('label.pin') }}: {{ orderItem.service_pin }}
                      <v-btn small class="ml-2 backgroundColor--white" elevation="0" tile icon @click="copyServicePin(orderItem.service_pin)">
                        <v-icon small class="stroke--light_gray" v-text="'$copyIcon'"></v-icon>
                      </v-btn>
                    </p>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col cols="12" sm="auto" class="text-sm-left text-center">
              <span class="text-sm-body-1 text-body-2">{{ `${$t(`label.quantity`)}: ` }}</span>
              <span class="text-h6 font-weight-bold mb-2">{{ `x${orderItem.quantity}` }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-divider class="orderHistory-info-divider"></v-divider>
        </v-col>

        <v-col cols="12" class="pa-4">
          <table class="orderHistory-info-table">
            <tr>
              <td class="history-table-left-info">
                <p class="mb-0 text-sm-body-1 text-body-2 text_light_gray--text">{{ $t(`label.subtotal`) }}</p>
              </td>
              <td class="text-right text-sm-left">
                <p class="mb-0 text-sm-body-1 text-body-2">{{ `${$shared.formatCurrency(calculateSubtTotal())}` }}</p>
              </td>
            </tr>
            <tr>
              <td class="history-table-left-info">
                <p class="mb-0 text-sm-body-1 text-body-2 text_light_gray--text">{{ $t(`label.shippingFee`) }}</p>
              </td>
              <td class="text-right text-sm-left">
                <p class="mb-0 text-sm-body-1 text-body-2">{{ `${$shared.formatCurrency(detail.shippingFee)}` }}</p>
              </td>
            </tr>
            <tr>
              <td class="history-table-left-info">
                <p class="mb-0 text-sm-body-1 text-body-2 text_light_gray--text">{{ $t(`label.promoCode`) }}</p>
              </td>
              <td class="text-right text-sm-left">
                <p class="mb-0 text-sm-body-1 text-body-2">{{ detail.discountCode != null ? detail.discountCode : '-' }}</p>
              </td>
            </tr>
            <tr>
              <td class="history-table-left-info">
                <p class="mb-0 text-sm-body-1 text-body-2 text_light_gray--text">{{ $t(`label.discount`) }}</p>
              </td>
              <td class="text-right text-sm-left">
                <p class="mb-0 text-sm-body-1 text-body-2">{{ `- ${$shared.formatCurrency(detail.discountAmount)}` }}</p>
              </td>
            </tr>
            <tr>
              <td class="history-table-left-info">
                <p class="mb-0 text-sm-body-1 text-body-2 text_light_gray--text">{{ $t(`label.deposit`) }}</p>
              </td>
              <td class="text-right text-sm-left">
                <p class="mb-0 text-sm-body-1 text-body-2">{{ detail.depositAmount != null || detail.depositAmount != 0 ? `- ${$shared.formatCurrency(detail.depositAmount)}` : '-' }}</p>
              </td>
            </tr>
            <tr>
              <td class="history-table-left-info">
                <p class="mb-0 text-sm-body-1 text-body-2 text_light_gray--text">{{ $t(`label.inTotal`) }}</p>
              </td>
              <td class="text-right text-sm-left">
                <p class="mb-0 text-sm-body-1 text-body-2 unbox_red--text font-weight-bold">{{ `${$shared.formatCurrency(detail.purchasePrice - detail.depositAmount)}` }}</p>
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </div>

    <!-- History Detail (Payment Method) -->
    <div>
      <div class="px-4">
        <div class="font-weight-bold text-body-2">
          <v-icon v-text="'$paymentMethodIcon'" class="stroke--unbox_primary text-body-1"></v-icon>
          {{ $t(`label.paymentMethod`) }}
        </div>

        <div class="mt-1 text-sm-body-1 text-body-2" v-if="detail.paymentInfo.length > 0">
          {{ $t(`paymentMethods.${detail.paymentInfo[0].payment_method.toLowerCase()}`) }}
        </div>
        <div class="mt-1 text-sm-body-1 text-body-2" v-else>-</div>
      </div>
      <v-divider class="my-2"></v-divider>
    </div>
    <!-- History Detail (Other Info And Timeline) -->
    <div class="px-4">
      <table class="orderHistory-info-table">
        <tr>
          <td class="history-table-left-info d-sm-table-cell d-block">
            <p class="mb-0 text-sm-body-1 text-body-2 text_light_gray--text">{{ $t(`label.orderId`) }}</p>
          </td>
          <td class="d-sm-table-cell d-block">
            <p class="mb-0 text-sm-body-1 text-body-2">{{ detail.orderReferenceNumber }}</p>
          </td>
        </tr>
      </table>

      <table class="orderHistory-info-table" v-if="detail.orderStatusHistory.length > 0">
        <tr v-for="orderHistory in detail.orderStatusHistory" :key="orderHistory.uuid">
          <td class="history-table-left-info d-sm-table-cell d-block">
            <p class="mb-0 text-sm-body-1 text-body-2 text_light_gray--text">{{ $t(`historyOrderStatusTimeLine.${orderHistory.to_status.toLowerCase()}`) }}</p>
          </td>
          <td class="d-sm-table-cell d-block">
            <p class="mb-0 text-sm-body-1 text-body-2">{{ $shared.getDateFormat(orderHistory.updated_date_time) }}</p>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { SHARED, DELIVERY_METHOD } from '@/constants'
import startCase from 'lodash/startCase'
export default {
  props: {
    detail: {
      type: Object,
      required: true,
      default: null
    },
    pickUpAddress: {
      type: Object,
      required: true,
      default: null
    },
    isCompletedTab: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      shared: SHARED,
      deliveryMethod: DELIVERY_METHOD
    }
  },
  methods: {
    copyTrackingNumber() {
      this.$copyText(this.detail.trackingNumber)
    },
    getProductVariantDisplay(variant) {
      return variant
        .map((o) => {
          return o.value
        })
        .join('/')
    },
    calculateSubtTotal() {
      if (this.detail === null || this.detail.orderItems.length <= 0) {
        return 0
      } else if (this.isCompletedTab) {
        let subtotal = 0
        let filteredItem = this.detail.orderItems.filter((item) => item.order_statuses_name.toLowerCase() != 'exchanged')

        filteredItem.forEach((x) => {
          subtotal += x.quantity * x.selling_price
        })

        return subtotal
      } else {
        let subtotal = 0
        this.detail.orderItems.forEach((x) => {
          subtotal += x.quantity * x.selling_price
        })

        return subtotal
      }
    },
    toStartCase(str) {
      return startCase(str)
    },
    copyServicePin(pinCode) {
      this.$copyText(pinCode)
    }
  }
}
</script>

<style lang="scss">
.orderHistory-info-divider {
  border-top: 2px solid #d5dde0;
}

@media only screen and (max-width: 599px) {
  .history-table-left-info {
    width: 110px;
    &.product-image {
      width: 200px;
    }
  }
  .history-product-image-box {
    width: 100%;
  }
}
</style>
